import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxEzModalOutletComponent} from './ngx-ez-modal-outlet.component';
import {NgxEzModalService} from './ngx-ez-modal.service';
import {NgxEzModalItemComponent} from './ngx-ez-modal-item';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NgxEzModalOutletComponent,
    NgxEzModalItemComponent
  ],
  exports: [
    NgxEzModalOutletComponent,
    NgxEzModalItemComponent
  ]
})
export class NgxEzModalModule {

  public static forRoot(): ModuleWithProviders<NgxEzModalModule> {
    return {
      ngModule: NgxEzModalModule,
      providers: [NgxEzModalService]
    };
  }

  public static forChild(): ModuleWithProviders<NgxEzModalModule> {
    return {
      ngModule: NgxEzModalModule,
      providers: [NgxEzModalService]
    };
  }

}
