import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValorantRedirectResolver  {
  constructor(private cookie: CookieService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.cookie.set('gg-game', 'valorant', 3650, '/');
    return of('/');
  }
}
