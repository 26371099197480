import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { ValorantRedirectResolver } from './resolvers/valorant-redirect';
import { RedirecterComponent } from './components/redirecter/redirecter.component';
import { LangGuard } from './guards/lang.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', pathMatch: 'full', loadChildren: () => import('./modules/home/public-home.module').then(m => m.PublicHomeModule) },
      { path: 'buy-authenticate', loadChildren: () => import('./modules/buy-authenticate/buy-authenticate.module').then(m => m.BuyAuthenticateModule) },
      { path: 'authenticate', loadChildren: () => import('./modules/buy-authenticate/buy-authenticate.module').then(m => m.BuyAuthenticateModule) },
      { path: 'tutorial', loadChildren: () => import('./modules/tutorial/public-tutorial.module').then(m => m.PublicTutorialModule) },
      { path: 'faq', loadChildren: () => import('./modules/faq/public-faq.module').then(m => m.PublicFaqModule) },
      { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.PublicContactModule) },
      { path: 'terms-of-use', loadChildren: () => import('./modules/terms-and-conditions/public-terms-and-conditions.module').then(m => m.PublicTermsAndConditionsModule) },
      { path: 'apex-legends', loadChildren: () => import('./modules/game-page/game-page.module').then(m => m.GamePageModule) },
      { path: 'privacy-policy', loadChildren: () => import('./modules/privacy-policy/public-privacy-policy.module').then(m => m.PublicPrivacyPolicyModuleModule) },
      { path: 'reviews', loadChildren: () => import('./modules/reviews/public-reviews.module').then(m => m.PublicReviewsModule) },
      { path: '', loadChildren: () => import('./modules/elo-boost/elo-boost.module').then(m => m.PublicEloBoostModule) },
      { path: 'service-page', loadChildren: () => import('./modules/service-page/service-page.module').then(m => m.ServicePageModule) },
      { path: 'auth/reset/:token', loadChildren: () => import('./modules/password-reset/password-reset.module').then(m => m.PasswordResetModule) },
      { path: 'booster-application', loadChildren: () => import('./modules/booster-application/booster-application.module').then(m => m.BoosterApplicationModule) },
      { path: 'payment-gateway', loadChildren: () => import('./modules/payment-success/payment-success.module').then(m => m.PaymentSuccessModule) },
      { path: 'blog', loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule) },
      { path: '404', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule) },
      { path: 'valorant', resolve: { redirect: ValorantRedirectResolver }, component: RedirecterComponent },
      {
        path: ':lang',
        canActivate: [LangGuard],
        children: [
          { path: '', pathMatch: 'full', loadChildren: () => import('./modules/home/public-home.module').then(m => m.PublicHomeModule) },
          { path: 'buy-authenticate', loadChildren: () => import('./modules/buy-authenticate/buy-authenticate.module').then(m => m.BuyAuthenticateModule) },
          { path: 'authenticate', loadChildren: () => import('./modules/buy-authenticate/buy-authenticate.module').then(m => m.BuyAuthenticateModule) },
          { path: 'tutorial', loadChildren: () => import('./modules/tutorial/public-tutorial.module').then(m => m.PublicTutorialModule) },
          { path: 'faq', loadChildren: () => import('./modules/faq/public-faq.module').then(m => m.PublicFaqModule) },
          { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.PublicContactModule) },
          { path: 'terms-of-use', loadChildren: () => import('./modules/terms-and-conditions/public-terms-and-conditions.module').then(m => m.PublicTermsAndConditionsModule) },
          { path: 'apex-legends', loadChildren: () => import('./modules/game-page/game-page.module').then(m => m.GamePageModule) },
          { path: 'privacy-policy', loadChildren: () => import('./modules/privacy-policy/public-privacy-policy.module').then(m => m.PublicPrivacyPolicyModuleModule) },
          { path: 'reviews', loadChildren: () => import('./modules/reviews/public-reviews.module').then(m => m.PublicReviewsModule) },
          { path: '', loadChildren: () => import('./modules/elo-boost/elo-boost.module').then(m => m.PublicEloBoostModule) },
          { path: 'service-page', loadChildren: () => import('./modules/service-page/service-page.module').then(m => m.ServicePageModule) },
          { path: 'auth/reset/:token', loadChildren: () => import('./modules/password-reset/password-reset.module').then(m => m.PasswordResetModule) },
          { path: 'booster-application', loadChildren: () => import('./modules/booster-application/booster-application.module').then(m => m.BoosterApplicationModule) },
          { path: 'payment-gateway', loadChildren: () => import('./modules/payment-success/payment-success.module').then(m => m.PaymentSuccessModule) },
          { path: 'blog', loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule) },
          { path: '404', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule) },
          { path: 'valorant', resolve: { redirect: ValorantRedirectResolver }, component: RedirecterComponent },
        ]
      },
    ],
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting { }
