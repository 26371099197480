import {NgModule} from '@angular/core';
import {AppRouting} from './app.routing';
import {AppComponent} from './app.component';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {NgxEzModalModule} from '../../shared/modules/ngx-ez-modal/ngx-ez-modal.module';
import {CoreModule} from '../../shared/modules/core/core.module';
import {BaseUiModule} from './shared/base-ui.module';
import {ToastrModule} from 'ngx-toastr';
import {ReviewService} from './services/review.service';
import {RedirecterComponent} from './components/redirecter/redirecter.component';
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {SharedModule} from "../../shared/shared.module";
import { TranslatePipeModule } from '../../shared/pipes/translate.pipe';

@NgModule({
  declarations: [
    AppComponent,
    RedirecterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRouting,
    BaseUiModule,
    CoreModule,
    NgxEzModalModule.forRoot(),
    ToastrModule.forRoot(),
    GoogleTagManagerModule.forRoot({ id: 'GTM-5Z82LX' }),
    TranslatePipeModule
  ],
  providers: [
    provideClientHydration(),
    ReviewService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
