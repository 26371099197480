import { Component, Inject, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from '../../../../shared/services/translations.service';
import { LanguageChangeService } from '../../../../shared/services/language-change.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() lang: string = '';

  ggLogo = 'https://cdn.ggboost.com/images/gg_logo_white.webp';
  ggLogoFallback = 'https://cdn.ggboost.com/images/gg_logo_white.png';
  methods = 'https://cdn.ggboost.com/images/visa-method.webp';
  year = new Date().getFullYear();

  langUrlPrefix: string = '';

  page = {
    en: {
      footerText: "League of Legends is a registered trademark of Riot Games, Inc. We are in no way affiliated with, associated with or endorsed by Riot Games, Inc.",
      allRightsReserved: "All Rights Reserved"
    },
    fr: {
      footerText: "League of Legends est une marque déposée de Riot Games, Inc. Nous ne sommes en aucun cas affiliés, associés ou approuvés par Riot Games, Inc.",
      allRightsReserved: "Tous droits réservés"
    },
    de: {
      footerText: "League of Legends ist ein eingetragenes Warenzeichen von Riot Games, Inc. Wir sind in keiner Weise mit Riot Games, Inc. verbunden, assoziiert oder von Riot Games, Inc. unterstützt.",
      allRightsReserved: "Alle Rechte vorbehalten."
    },
    pt: {
      footerText: "League of Legends é uma marca registada da Riot Games, Inc. Não temos qualquer afiliação, associação ou suporte da Riot Games, Inc.",
      allRightsReserved: "Todos os direitos reservados"
    },
    es: {
      footerText: "League of Legends es una marca registrada de Riot Games, Inc. No estamos de ninguna manera afiliados, asociados o respaldados por Riot Games, Inc.",
      allRightsReserved: "Todos los derechos reservados"
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private cookie: CookieService,
    private languageChangeService: LanguageChangeService
  ) { }

  ngOnInit() {
    this.languageChangeService.languageChanged.subscribe((newLang: string) => {
      this.lang = newLang;

      if (this.lang !== 'en') {
        this.langUrlPrefix = '/' + this.lang;
      } else {
        this.langUrlPrefix = '';
      }
    });

    this.route.params.subscribe(params => {
      if (Object.keys(params).length === 0) {
        const url = this.router.url.split('/');
        if (this.translationService.validLangs.includes(url[1])) {
          this.lang = url[1];
        } else {
          this.lang = this.cookie.get('gg-lang') || 'en';
        }
      } else {
        this.lang = params['lang'] || 'en';
      }

      if (this.lang !== 'en') {
        this.langUrlPrefix = '/' + this.lang;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang && !changes.lang.firstChange) {
      this.updateLangUrlPrefix();
    }
  }

  private updateLangUrlPrefix() {
    if (this.lang && this.lang !== 'en') {
      this.langUrlPrefix = '/' + this.lang;
    } else {
      this.langUrlPrefix = '';
    }
  }
}