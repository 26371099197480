import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.userService.getUser().subscribe((user: any) => {
      if (user && (user.role === 'admin' || user.role === 'superadmin')) {
        req = req.clone({headers: req.headers.set('cf-site-admin', 'b5bea41b6c623f7c09f1bf24dcae58ebab3c0cdd90ad966bc43a45b44867e12b')});
      }
    });

    if (!req.headers.has('Content-Type')) {
      req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
    }

    if (!req.url.startsWith('http://') && !req.url.startsWith('https://')) {
      req = req.clone({url: environment.apiUrl.concat(req.url)});
      req = req.clone({headers: req.headers.set('Accept', 'application/json, application/octet-stream')});
      req = req.clone({withCredentials: true});
      req = req.clone({headers: req.headers.set('Cache-Control','no-cache')});
      req = req.clone({headers: req.headers.set('Pragma','no-cache')});
      req = req.clone({headers: req.headers.set('Expires','Sat, 01 Jan 2000 00:00:00 GMT')});
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }, (error: any) => {
        if (error instanceof HttpErrorResponse) {
        }
      }));
  }
}
