import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { UserService } from '../../../../shared/modules/core/services/user.service';
import { DivisionsService } from '../../../../shared/modules/core/services/divisions.service';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LanguageChangeService } from '../../../../shared/services/language-change.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() lang: string;

  user$: Observable<any>;
  baseUrl = '';
  base;
  isCustomer = false;
  isMobileNavOpen = false;
  isScrolled = false;
  isStatic = false;
  // lang: string = '';
  countries = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fr', name: 'Français' },
    { code: 'es', name: 'Español' },
    { code: 'pt', name: 'Português' }
  ];
  selectedCountry = this.countries[0];
  dropdownOpen = false;
  engFlag = 'gb';

  private usTimezones = [
    'America/New_York',    // Eastern Time
    'America/Detroit',     // Eastern Time
    'America/Kentucky/Louisville', // Eastern Time
    'America/Kentucky/Monticello', // Eastern Time
    'America/Indiana/Indianapolis', // Eastern Time
    'America/Indiana/Vincennes', // Eastern Time
    'America/Indiana/Winamac', // Eastern Time
    'America/Indiana/Marengo', // Eastern Time
    'America/Indiana/Petersburg', // Eastern Time
    'America/Indiana/Vevay', // Eastern Time
    'America/Chicago',      // Central Time
    'America/Indiana/Tell_City', // Central Time
    'America/Indiana/Knox', // Central Time
    'America/Menominee',    // Central Time
    'America/North_Dakota/Center', // Central Time
    'America/North_Dakota/New_Salem', // Central Time
    'America/North_Dakota/Beulah', // Central Time
    'America/Denver',       // Mountain Time
    'America/Boise',        // Mountain Time
    'America/Phoenix',      // Mountain Standard Time (no DST)
    'America/Los_Angeles',  // Pacific Time
    'America/Anchorage',    // Alaska Time
    'America/Juneau',       // Alaska Time
    'America/Sitka',        // Alaska Time
    'America/Metlakatla',   // Alaska Time
    'America/Yakutat',      // Alaska Time
    'America/Nome',         // Alaska Time
    'America/Adak',         // Hawaii-Aleutian Time
    'Pacific/Honolulu'      // Hawaii-Aleutian Standard Time (no DST)
  ];

  private isBrowser = false;
  private dontScroll = ['/lol-boost', '/valorant-boost'];

  constructor(
    private userService: UserService,
    private router: Router,
    private cookie: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private languageChangeService: LanguageChangeService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.base = window.location.origin;

      if (window.location.pathname.indexOf('my-account') > -1) {
        this.isCustomer = true;
        this.baseUrl = window.location.origin;
        if (this.cookie.check('gg-lang')) {
          this.selectCountry(this.countries.find(item => item.code === this.cookie.get('gg-lang')));
        } else {
          this.selectCountry(this.languageChangeService.getLanguage());
        }
      } else {
        this.baseUrl = '';
        // let tmpCountry;
        // if (this.cookie.check('gg-lang') && (this.cookie.get('gg-lang') === (this.router.url.split('/')[1] || 'en'))) {
        //   tmpCountry = this.countries.find(item => item.code === this.cookie.get('gg-lang'));;
        // } else {
        //   tmpCountry = this.countries.find(item => item.code === (this.router.url.split('/')[1] || 'en'));
        // }
        // this.lang = tmpCountry.code;

        // this.selectCountry(tmpCountry)
      }
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isUserInUS = this.usTimezones.includes(userTimeZone);

      if (isUserInUS) {
        this.engFlag = 'us';
      }

    } else {
      this.base = '';
      this.baseUrl = '';
    }
  }

  ngOnInit(): void {
    this.user$ = this.userService.getUser().pipe(
      share(),
      map(val => (val === null || val === undefined ? {} : val))
    );

    this.userService.userUpdated.subscribe(user => {
      this.user$ = of(user);
    });

    this.router.events.subscribe(event => {
      // Scroll to top if accessing a page, not via browser history stack
      if (event instanceof NavigationEnd) {
        this.toggleMobileNav(false);
      }

      if (this.isBrowser) {
        document.querySelector('body').classList.remove('mobile-nav');
      }
    });

    this.languageChangeService.languageChanged.subscribe((newLang: string) => {
    });

    this.selectedCountry = this.countries.find(item => item.code === this.lang);

    // if (this.isBrowser) {
    //   // const htmlElement = document.querySelector('html');
    //   // htmlElement.setAttribute('lang', this.lang);
    // }
  }

  openAccount(event) {
    event.preventDefault();
    this.user$.subscribe(user => {
      if (!user || !user.role) {
        window.location.href = this.getUrl('authenticate');
        return;
      }

      switch (user.role) {
        case 'customer':
          window.location.href = '/my-account';
          break;
        case 'booster':
          window.location.href = '/booster';
          break;
        case 'admin':
        case 'superadmin':
          window.location.href = '/admin';
          break;
        default:
          window.location.href = '/authenticate';
      }
    });
  }

  goTo(event, url) {
    event.preventDefault();
    if (this.baseUrl) {
      window.location.href = this.baseUrl + url;
    } else {
      this.router.navigate([url]);
    }
  }

  goToServices(event) {
    event.preventDefault();
    if (this.baseUrl) {
      window.location.href = this.baseUrl + '/' + (this.cookie.get('gg-game') || 'lol') + '-boost';
    } else {
      this.router.navigate(['/' + (this.cookie.get('gg-game') || 'lol') + '-boost']);
    }
  }

  toggleMobileNav(val: boolean) {
    this.isMobileNavOpen = val;

    if (this.isBrowser && val) {
      document.querySelector('html').classList.add('mobile-nav');
    }
    if (this.isBrowser && !val) {
      document.querySelector('html').classList.remove('mobile-nav');
    }
  }

  onLogout($event) {
    $event.preventDefault();

    this.userService.logout();
    this.router.navigateByUrl('/');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown')) {
      this.dropdownOpen = false;
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectCountry(country: any) {
    this.selectedCountry = country;
    this.dropdownOpen = false;
    this.lang = country.code;

    let url = this.router.url.split('/');
    if (country.code === 'en') {
      if (url.length >= 2) {
        url.splice(1, 1);
      }
    } else {
      if (['de', 'fr', 'es', 'pt'].indexOf(url[1]) > -1) {
        url[1] = this.selectedCountry.code;
      } else {
        url.splice(1, 0, country.code);
      }
    }

    if (url[url.length - 1] === '' && url.length > 1) {
      url.splice(url.length - 1, 1);
    }
    this.cookie.set('gg-lang', country.code, 3650, '/');
    this.languageChangeService.setLanguage(country.code);

    if (window.location.pathname.indexOf('my-account') === -1) {
      url[url.length - 1] = (url[url.length - 1].split('?')[0]);
      this.router.navigate(url);
    }

    // const htmlElement = document.querySelector('html');
    // htmlElement.setAttribute('lang', this.lang);
  }

  getUrl(page: string) {
    let url: string = '';

    if (this.isCustomer) {
      url = this.baseUrl;
    }

    if (this.lang === 'en') {
      url += '/' + page;
    } else {
      url += '/' + this.lang + '/' + page;
    }

    return url;
  }
}