import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translations.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService) { }

  transform(value: string, lang: string): string {
    return this.translationService.getTranslation(lang, value);
  }
}
@NgModule({
  declarations: [TranslatePipe],
  exports: [TranslatePipe]
})
export class TranslatePipeModule {
}
