import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-redirecter',
  template: ''
})
export class RedirecterComponent {
  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.navigateByUrl(route.snapshot.data.redirect);
  }
}
