import {Component, Input, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: 'ngx-ez-modal-item',
  template: `
    <div class="ezModal__dialog">
      <ng-container #modalItemContent></ng-container>
    </div>
  `,
})

export class NgxEzModalItemComponent {
  @Input() dialogClass;
  @ViewChild('modalItemContent', {static: true, read: ViewContainerRef}) modalItemContent: ViewContainerRef;
}
