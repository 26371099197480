import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {NgxEzModalService} from './ngx-ez-modal.service';
import {Subscription} from 'rxjs';
import {NgxEzModalOptions} from './ngx-ez-modal';
import {animate, style} from '@angular/animations';
import * as deepExtend from 'deep-extend';

const defaultItemAnimationStateStyles = {
  hey: [style({left: '*'}), animate('300ms ease-in-out', style({left: 0}))],
  bye: [style({left: '*'}), animate('300ms ease-in-out', style({left: 0}))],
  'new-to-show': [
    style({left: '100%'}),
    animate('300ms ease-in-out', style({left: '0'}))
  ],
  'show-to-new': [
    style({left: '*'}),
    animate('300ms ease-in-out', style({left: '100%'}))
  ],
  'show-to-hide': [
    style({left: '*'}),
    animate('300ms ease-in-out', style({left: '-100%'}))
  ],
  'hide-to-show': [
    style({left: '*'}),
    animate('300ms ease-in-out', style({left: '0'}))
  ]
};

const defaultItemDialogAnimationStateStyles = {
  hey: [
    style({transform: 'scale(.5) rotateY(0)', opacity: 0}),
    animate(
      '200ms ease-in-out',
      style({transform: 'scale(1) rotateY(0)', opacity: 1})
    )
  ],
  bye: [
    style({transform: 'scale(1) rotateY(0)', opacity: 1}),
    animate(
      '200ms ease-in-out',
      style({transform: 'scale(.5) rotateY(0)', opacity: 0})
    )
  ],
  'new-to-show': [
    style({transform: 'scale(1) rotateY(-90deg)'}),
    animate('200ms ease-in-out', style({transform: 'scale(1) rotateY(0)'}))
  ],
  'show-to-new': [
    style({transform: 'scale(1) rotateY(0)'}),
    animate(
      '200ms ease-in-out',
      style({transform: 'scale(1) rotateY(-90deg)'})
    )
  ],
  'show-to-hide': [
    style({transform: 'scale(1) rotateY(0)'}),
    animate(
      '200ms ease-in-out',
      style({transform: 'scale(1) rotateY(90deg)'})
    )
  ],
  'hide-to-show': [
    style({transform: 'scale(1) rotateY(90deg)'}),
    animate('200ms ease-in-out', style({transform: 'scale(1) rotateY(0)'}))
  ]
};

@Component({
  // tslint:disable-next-line
  selector: 'ngx-ez-modal-outlet',
  template: `
    <div
      [ngClass]="
        options.baseClass +
        ' ' +
        (stackCount === 0 ? options.wrapperHiddenClass : '')
      "
      class="{{ options.wrapperClass }}"
    >
      <div class="{{ options.overlayClass }}"></div>
      <div class="{{ options.containerClass }}">
        <ng-container #ezModalContent></ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./ngx-ez-modal.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgxEzModalOutletComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() outletOptions: NgxEzModalOptions;
  @ViewChild('ezModalContent', {static: true, read: ViewContainerRef})
  contentRef: ViewContainerRef;

  public options: NgxEzModalOptions;
  public stackCount = 0;

  private name = 'global';

  private readonly defaultOptions: NgxEzModalOptions = {
    allowDuplicates: false,
    respectAnimation: true,
    baseClass: 'ezModal--animset-3',
    modalOpenBodyClass: 'modal-open',
    wrapperClass: 'ezModal',
    overlayClass: 'ezModal__overlay',
    containerClass: 'ezModal__container',
    dialogClass: 'ezModal__dialog',
    wrapperHiddenClass: 'ezModal--hidden',
    wrapperOpenClass: 'ezModal--shown',
    modalItemClass: 'ezModal__item',
    defaultItemWidth: '500px',
    useAnimationBuilder: true,
    itemAnimations: {
      init: {class: 'ezModal__item--hey', duration: 300},
      close: {class: 'ezModal__item--bye', duration: 300},
      newToShow: {class: 'ezModal__item--new-to-show', duration: 300},
      showToNew: {class: 'ezModal__item--show-to-new', duration: 300},
      showToHide: {class: 'ezModal__item--show-to-hide', duration: 300},
      hideToShow: {class: 'ezModal__item--hide-to-show', duration: 300}
    },
    itemAnimationStateStyles: defaultItemAnimationStateStyles,
    itemDialogAnimationStateStyles: defaultItemDialogAnimationStateStyles
  };

  private updatedSub: Subscription;

  @HostListener('mousedown', ['$event.target']) onClick(target) {
    if (
      target.classList.contains(this.options.containerClass) ||
      target.classList.contains(this.options.modalItemClass)
    ) {
      if (this.modalService.getOpenItemOptions(this.name).closeOnOutsideClick !== false) {
        this.modalService.closeOutletModals(this.name);
      }
    }
  }

  constructor(private modalService: NgxEzModalService) {
  }

  ngOnInit() {
    this.options = deepExtend(
      this.defaultOptions,
      this.outletOptions ? this.outletOptions : {}
    );

    this.modalService.updatedEmitter.subscribe((outletName: string) => {
      if (outletName === this.name) {
        this.stackCount = this.modalService.getOutletStackCount(this.name);
      }
    });
  }

  ngAfterViewInit() {
    this.modalService.attachOutlet(this.name, this.contentRef, this.options);
  }

  ngOnDestroy() {
    if (this.updatedSub) {
      this.updatedSub.unsubscribe();
    }
    this.modalService.closeOutletModals(this.name);
    this.modalService.detachOutlet(this.name);
  }
}
