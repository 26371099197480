import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ObservableQueueService {
  private observables: { [key: string]: Observable<any> } = {};

  public run<T>(token: string, target: Observable<T>): Observable<T> {
    if (this.observables[token]) {
      return this.observables[token];
    }

    this.observables[token] = target.pipe(
      tap(() => delete this.observables[token]),
      shareReplay(1),
      catchError(error => {
        delete this.observables[token];
        return throwError(error);
      })
    );

    return this.observables[token];
  }
}
