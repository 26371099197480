import { NgModule } from '@angular/core';
import { ServiceLabelComponent } from './components/service-label/service-label.component';
import { CommonModule } from '@angular/common';
import { ForTimesDirective } from './directives/for-times.directive';
import { NgxEzModalModule } from './modules/ngx-ez-modal/ngx-ez-modal.module';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from './modules/button/button.module';
import { Cs2SvgSelector } from "./components/cs2-svg-selector/cs2-svg-selector";
import { TranslatePipeModule } from '../shared/pipes/translate.pipe';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, TranslatePipeModule],
  declarations: [ServiceLabelComponent, ForTimesDirective, CountrySelectorComponent, Cs2SvgSelector],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ServiceLabelComponent,
    ButtonModule,
    ForTimesDirective,
    NgxEzModalModule,
    CountrySelectorComponent,
    Cs2SvgSelector
  ]
})
export class SharedModule {
}