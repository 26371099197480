import {EventEmitter, Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {ObservableQueueService} from './observablequeue.service';
import { TranslationService } from '../../../services/translations.service';

@Injectable()
export class UserService {
  userUpdated: EventEmitter<any> = new EventEmitter();
  private user: { nickname: string, role: string };
  private readonly isBrowser: boolean = true;

  public constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: string,
    private cookie: CookieService,
    private queue: ObservableQueueService,
    private translationService: TranslationService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public loginEmail(email, orderToken) {
    if (!this.isBrowser) {
      return;
    }
    const method = 'email';
    return this.http.post('/auth/login', {
      method, email, orderToken
    }).pipe(
      tap((response: any) => {
        if (response.success) {
          this.userUpdated.emit(response.user);
          this.user = response.user;
          this.setRememberToken(response.rememberToken);
        }
      })
    );
  }

  public loginExternal(accessToken, state, method) {
    if (!this.isBrowser) {
      return;
    }
    return this.http.post('/auth/login', {
      accessToken, state, method
    }).pipe(
      tap((response: any) => {
        if (response.success) {
          this.userUpdated.emit(response.user);
          this.user = response.user;
          this.setRememberToken(response.rememberToken);
        }
      })
    );
  }

  public login(username, password, remember, token = null): Observable<any> {
    if (!this.isBrowser) {
      return;
    }
    return this.http.post(token ? '/auth/login/' + token : '/auth/login', {username, password, remember: true}).pipe(
      tap((val: any) => {
        if (val.user) {
          this.userUpdated.emit(val.user);
          this.user = val.user;
          this.setRememberToken(val.rememberToken);
        }
      })
    );
  }

  public resetPassword(email): Observable<any> {
    if (!this.isBrowser) {
      return;
    }
    return this.http.post('/auth/remind', {email}).pipe(
      tap((val: any) => {
      })
    );
  }

  public rememberLogin() {
    if (!this.isBrowser) {
      return;
    }

    if (this.cookie.check('gg-rtkn')) {
      this.http.post('/auth/login', {
        rememberToken: this.cookie.get('gg-rtkn')
      }).pipe(
        tap((val: any) => {
          if (val.user) {
            this.userUpdated.emit(val.user);
            this.user = val.user;
            this.setRememberToken(val.rememberToken);
          }
        })
      ).subscribe();
    }
  }

  public verify(token: string): Observable<any> {
    return this.http.post('/auth/verify', {token}).pipe(
      tap((val: any) => {
        if (val.rememberToken) {
          this.setRememberToken(val.rememberToken);
        }
      })
    );
  }

  public getOrderData(orderToken): Observable<any> {
    if (!this.isBrowser) {
      return;
    }
    return this.http.post('/order-details', {orderToken}).pipe(
      tap((val: any) => {
        if (val.user) {
          this.userUpdated.emit(val.user);
          this.user = val.user;
          this.setRememberToken(val.rememberToken);
        }
      })
    );
  }

  public register(email, password, username): Observable<any> {
    if (!this.isBrowser) {
      return;
    }
    const body = {email, password, username};
    return this.http.post('/auth/register', body).pipe(
      tap((val: any) => {
        if (val.user) {
          this.userUpdated.emit(val.user);
          this.user = val.user;
          this.setRememberToken(val.rememberToken);
        }
      })
    );
  }

  public logout(): void {
    if (!this.isBrowser) {
      return;
    }
    this.user = undefined;
    this.http.get('/auth/logout').subscribe(() => {
      this.cookie.delete('gg-rtkn', '/');
      this.userUpdated.emit({});

      let url = '/';
      if (this.cookie.check('gg-lang')) {
        const tmpLang = this.cookie.get('gg-lang');
        if (this.translationService.validLangs.includes(tmpLang) && tmpLang !== 'en') {
          url += tmpLang;
        }
      }

      window.location.href = url;
    });
  }

  public getUser(): Observable<any> {
    // Server... ignore, of(null) is observable
    if (!this.isBrowser) {
      return of(null);
    }

    if (this.user) {
      return of(this.user);
    }

    return this.queue.run('getUser', this.http.get('/auth/user',{withCredentials:true, headers:{'accept':'text/json'}}).pipe(
      tap((val: any) => {
        if (val) {
          this.user = val;
          this.userUpdated.emit(val);
        }
      })
    ));
  }

  private setRememberToken(token: string) {
    if (token) {
      this.cookie.set('gg-rtkn', token, 3650, '/');
    }
  }
}