import {ChangeDetectionStrategy, Component, EventEmitter, Host, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() backgroundType: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' = 'primary';
  @Input() colorType: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' = 'light';
  @Input() hoverBackgroundType: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' = this.backgroundType;
  @Input() hoverColorType: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' = this.colorType;
  @Input() condensed: boolean;
  @Input() isLoading = false;
  @Input() size: 'lg' | 'sm' | 'form';
  @Input() margin = true;

  @Output() clicked = new EventEmitter();

  @HostBinding('class.--block') @Input() block: boolean;

  public class = 'ggBtn';

  ngOnInit(): void {
    this.class += ' ' + '--bg-' + this.backgroundType;
    this.class += ' ' + '--color-' + this.colorType;
    this.class += ' ' + '--hover-bg-' + this.hoverBackgroundType;
    this.class += ' ' + '--hover-color-' + this.hoverColorType;

    if (this.size) {
      this.class += ' ' + '--' + this.size;
    }
  }
}
