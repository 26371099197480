<button
  [disabled]="disabled"
  [class]="class"
  [class.--condensed]="condensed"
  [class.--block]="block"
  [class.--disabled]="disabled || isLoading"
  [class.--loading]="isLoading"
  [class.noMargin]="!margin"
  (click)="(!disabled && !isLoading) && clicked.emit($event)"
>
  <span><ng-content></ng-content></span>
  <div class="loader" *ngIf="isLoading"></div>
</button>
