import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangeService {
  languageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private cookieService: CookieService) { }

  setLanguage(lang: string) {
    this.cookieService.set('gg-lang', lang, 3650, '/');
    this.languageChanged.emit(lang);
  }

  getLanguage(): string {
    return this.cookieService.get('gg-lang');
  }
}