import {isPlatformBrowser} from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
  Inject,
  PLATFORM_ID, Component
} from '@angular/core';
import {
  NgxEzModalItemOptions,
  NgxEzModalOptions,
  NgxEzModalOutlet,
  NgxEzModalStackItem
} from './ngx-ez-modal';
import {NgxEzModalItemComponent} from './ngx-ez-modal-item';
import {AnimationBuilder} from '@angular/animations';
import {merge} from '../../utils/deep-merge';

@Injectable()
export class NgxEzModalService {
  private outlets: NgxEzModalOutlet[] = [];
  private isAnimating = false;
  private isBrowser = true;

  public updatedEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private cfr: ComponentFactoryResolver,
    private builder: AnimationBuilder,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public attachOutlet(
    name: string,
    container: ViewContainerRef,
    options: NgxEzModalOptions
  ): void {
    // Find outlet by name, die if not found
    if (this.outlets.find(el => el.name === name)) {
      // throw new Error('Duplicate outlet name');
      return;
    }

    // Add outlet to the outlet store
    this.outlets.push({
      name,
      container,
      containerNode: container.element.nativeElement.parentNode,
      stack: [],
      options
    });
  }

  public detachOutlet(name: string) {
    // Copy outlets and get current outlet's index
    const ref = this.outlets.slice();
    const index = this.outlets.findIndex(el => el.name === name);
    if (index === -1) {
      return;
      // throw new Error('Outlet not found!');
    }

    // Clear any remaining stuff
    this._clearOutlet(this.outlets[index]);

    // Remove current outlet
    ref.splice(index, 1);

    // Overwrite outlets
    this.outlets = ref;
  }

  public open(
    id: string,
    component: any,
    reserveStack?: boolean,
    options?: NgxEzModalItemOptions,
    resolver?: ComponentFactoryResolver
  ): Component | void {
    const outlet = 'global';
    // Get current outlet
    const outletRef = this._getOutletByName(outlet);

    // Check whether we have a duplicate and reject it based on config
    if (
      outletRef.stack.find(el => el.id === id) &&
      !outletRef.options.allowDuplicates
    ) {
      // throw new Error('Duplicate modal!');
    }

    // Don't allow opening of new item while we are working on another
    if (this.isAnimating && outletRef.options.respectAnimation) {
      return;
    }

    // Let's not forget we are starting to work
    this.isAnimating = true;

    // Create our wrapper component
    const modalItem = (
      this._createContentRef(NgxEzModalItemComponent, outletRef.container)
    ) as ComponentRef<any>;

    // Assign our dialog class to it
    modalItem.instance.dialogClass = outletRef.options.dialogClass;
    modalItem.instance.state = 'test';

    // Get the content wrapper placeholder
    const modalItemViewContainer = modalItem.instance.modalItemContent;

    // Create our content component / template
    const modalContent = (
      this._createContentRef(component, modalItemViewContainer, resolver)
    ) as ComponentRef<any> | TemplateRef<any>;

    // Get the wrapper HTML element
    const modalItemParentNode =
      modalItemViewContainer.element.nativeElement.parentNode.parentNode;

    // Get the dialog HTML element
    const modalItemDialogNode =
      modalItemViewContainer.element.nativeElement.parentNode;

    // Assign our item class to the wrapper element
    modalItemParentNode.classList.add(outletRef.options.modalItemClass);

    // Merge options
    const itemOptions = merge(options ? options : {}, outletRef.options);

    // Add our new item to the outlet stack
    const stackItem = {
      id,
      component: modalContent,
      itemNode: modalItemParentNode,
      dialogNode: modalItemDialogNode,
      options: itemOptions
    };
    outletRef.stack.push(stackItem);

    // Set width of dialog based on item config (defaults back to outlet config)
    modalItemDialogNode.style.width = itemOptions.width
      ? itemOptions.width
      : outletRef.options.defaultItemWidth;

    // Let our outlet know that we have a new item in the stack
    this.updatedEmitter.emit(outlet);

    // Declare animation duration
    let animationDuration = 0;

    // If this is our first stack item, let's initiate the modal
    if (outletRef.stack.length === 1) {
      this._initiateModal(outlet);
      animationDuration = stackItem.options.itemAnimations.init.duration;
    } else {
      // Set state of new and last existing modal items
      if (stackItem.options.useAnimationBuilder) {
        this._animateToState('new-to-show', stackItem);
        this._animateToState(
          'show-to-hide',
          outletRef.stack[outletRef.stack.length - 2]
        );
      } else {
        this._changeStateClass(
          stackItem.options.itemAnimations.newToShow.class,
          stackItem
        );
        this._changeStateClass(
          stackItem.options.itemAnimations.showToHide.class,
          outletRef.stack[outletRef.stack.length - 2]
        );
      }

      // Calc max animation duration
      animationDuration = Math.max(
        stackItem.options.itemAnimations.newToShow.duration,
        outletRef.stack[outletRef.stack.length - 2].options
          .itemAnimations.showToHide.duration
      );
    }

    // Do some stuff after animation is complete
    setTimeout(() => {
      // Reset animation indicator
      this.isAnimating = false;

      stackItem.dialogNode.classList.add('force-redraw');

      // If we don't want to reserve stack and we have more than one item, lets remove everything except the latest
      if (outletRef.stack.length > 1 && !reserveStack) {
        for (let i = 0; i < outletRef.container.length - 1; i++) {
          outletRef.container.remove(i);
        }
        outletRef.stack = outletRef.stack.slice(-1);

        // Let our outlets know we have updated our stack
        this.updatedEmitter.emit(outlet);
      }
    }, animationDuration);

    if (modalContent instanceof ComponentRef) {
      return modalContent.instance;
    }
  }

  public back(outletName: string) {
    // Get outlet, die if not found
    const outlet = this._getOutletByName('global');
    if (outlet.stack.length < 2) {
      return;
      // throw new Error('Nowhere to go back to :(');
    }

    const lastItem = outlet.stack[outlet.stack.length - 1];
    const returningItem = outlet.stack[outlet.stack.length - 2];

    // Apply state to last item
    if (lastItem.options.useAnimationBuilder) {
      this._animateToState('show-to-new', lastItem);
    } else {
      this._changeStateClass(
        lastItem.options.itemAnimations.showToNew.class,
        lastItem
      );
    }

    // Apply state to before last item
    if (returningItem.options.useAnimationBuilder) {
      this._animateToState('hide-to-show', returningItem);
    } else {
      this._changeStateClass(
        returningItem.options.itemAnimations.hideToShow.class,
        returningItem
      );
    }

    // Calculate max animation duration
    const animationDuration = Math.max(
      outlet.stack[outlet.stack.length - 1].options.itemAnimations
        .showToNew.duration,
      outlet.stack[outlet.stack.length - 2].options.itemAnimations
        .hideToShow.duration
    );

    // Wait until our animations finsih
    setTimeout(() => {
      this._removeLastItem(outlet);
    }, animationDuration);
  }

  public getOutletStackCount(name: string) {
    const outlet = this._getOutletByName(name);
    if (!outlet) {
      return 0;
    }

    return outlet.stack.length;
  }

  public closeOutletModals(name: string) {
    const outlet = this._getOutletByName('global');
    if (!outlet) {
      return;
      // throw new Error('Outlet not found');
    }

    // Return if we are currently animating
    if (this.isAnimating && outlet.options.respectAnimation) {
      return;
    }

    const lastStackItem = outlet.stack[outlet.stack.length - 1];
    if (!lastStackItem) {
      return;
    }

    this.isAnimating = true;

    const containerDOMElem = (
      outlet.containerNode.closest(`.${outlet.options.wrapperClass}`)
    ) as HTMLElement;
    containerDOMElem.classList.remove(outlet.options.wrapperOpenClass);

    if (lastStackItem.options.useAnimationBuilder) {
      this._animateToState('bye', lastStackItem);
    } else {
      this._changeStateClass(
        lastStackItem.options.itemAnimations.close.class,
        lastStackItem
      );
    }

    const animationDuration =
      outlet.stack[outlet.stack.length - 1].options.itemAnimations.close
        .duration;

    setTimeout(() => {
      this._clearOutlet(outlet);
    }, animationDuration);
  }

  public getOpenItemOptions(name: string): NgxEzModalItemOptions {
    const outlet = this._getOutletByName(name);
    if (!outlet) {
      return {};
      // throw new Error('Outlet not found');
    }

    const lastStackItem = outlet.stack[outlet.stack.length - 1];
    if (!lastStackItem) {
      return {};
    }

    return lastStackItem.options;
  }

  private _initiateModal(name: string): void {
    // Get outlet, die if not found
    const outlet = this._getOutletByName(name);
    if (!outlet) {
      return;
      // throw new Error('Outlet not found');
    }

    const stackItem = outlet.stack[0];

    if (stackItem.options.useAnimationBuilder) {
      this._animateToState('hey', stackItem);
    } else {
      stackItem.itemNode.classList.add(
        outlet.stack[0].options.itemAnimations.init.class
      );
    }

    if (this.isBrowser) {
      // Add class to body to prevent double scrollbars
      document
        .querySelector('body')
        .classList.add(outlet.options.modalOpenBodyClass);
    }

    // Find main wrapper element of outlet and add class to it to indicate it is open
    const containerDOMElem = outlet.containerNode.closest(
      `.${outlet.options.wrapperClass}`
    );
    containerDOMElem.classList.add(outlet.options.wrapperOpenClass);
  }

  private _getOutletByName(name: string) {
    const index = this.outlets.findIndex(el => el.name === name);
    if (index < 0) {
      return null;
      // throw new Error('Outlet not found!');
    }

    return this.outlets[index];
  }

  private _createContentRef(
    component: Type<any> | TemplateRef<any>,
    container: ViewContainerRef,
    resolver?: ComponentFactoryResolver
  ) {
    let componentFactory;
    let createdComponent;
    if (!(component instanceof TemplateRef)) {
      if (resolver) {
        componentFactory = resolver.resolveComponentFactory(component);
      } else {
        componentFactory = this.cfr.resolveComponentFactory(component);
      }

      createdComponent = container.createComponent(componentFactory);
    } else {
      createdComponent = container.createEmbeddedView(component);
    }

    return createdComponent;
  }

  private _animateToState(state: string, item: NgxEzModalStackItem) {
    const itemNode = item.itemNode;
    const dialogNode = item.dialogNode;

    const itemStateAnim = item.options.itemAnimationStateStyles[state];
    const dialogStateAnim =
      item.options.itemDialogAnimationStateStyles[state];

    const itemAnim = this.builder.build(itemStateAnim);
    const itemPlayer = itemAnim.create(itemNode);
    itemPlayer.play();

    const dialogAnim = this.builder.build(dialogStateAnim);
    const dialogPlayer = dialogAnim.create(dialogNode);
    dialogPlayer.play();
  }

  private _changeStateClass(state: string, item: NgxEzModalStackItem) {
    const appliedStateClasses = item.itemNode.className
      .split(' ')
      .filter(el => {
        return Object.keys(item.options.itemAnimations).find(x => {
          return item.options.itemAnimations[x].class === el;
        });
      });

    item.itemNode.classList.add(state);
    appliedStateClasses.forEach(el => {
      if (el !== state) {
        item.itemNode.classList.remove(el);
      }
    });
  }

  private _clearOutlet(outlet: NgxEzModalOutlet) {
    Array.from(outlet.stack).forEach(el => {
      if (el.component instanceof ComponentRef) {
        el.component.destroy();
      }
    });
    outlet.stack = [];
    outlet.container.clear();
    this.updatedEmitter.emit(outlet.name);
    this.isAnimating = false;

    if (this.isBrowser) {
      document
        .querySelector('body')
        .classList.remove(outlet.options.modalOpenBodyClass);
    }

    // Let our outlet know we have updated our stack
    this.updatedEmitter.emit(outlet.name);
  }

  private _removeLastItem(outlet: NgxEzModalOutlet) {
    // Perspective hack
    outlet.stack[outlet.stack.length - 2].dialogNode.classList.add(
      'force-redraw'
    );

    // Remove last elem from the list
    outlet.container.remove(outlet.container.length - 1);
    outlet.stack.pop();

    // Let our outlet know we have updated our stack
    this.updatedEmitter.emit(outlet.name);
  }
}
