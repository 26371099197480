import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, LocationStrategy } from '@angular/common';
import { filter, first, startWith, take } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../shared/modules/core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  private readonly isBrowser: boolean = true;
  private isPopState = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private dom: Document,
    private userService: UserService,
    private router: Router,
    private locStrat: LocationStrategy,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
    if (!isPlatformBrowser(platformId)) {
      this.isBrowser = false;
    }
  }

  ngOnInit() {
    this.locStrat.onPopState(() => {
      this.isPopState = true;
    });

    if (this.isBrowser) {
      const urlObj = new URL(this.dom.location.href);
      const referrer = this.dom.referrer;

      const gclid = urlObj.searchParams.get('gclid');
      const fbclid = urlObj.searchParams.get('fbclid');
      const msclkid = urlObj.searchParams.get('msclkid');
      let source = 'd';

      if (referrer) {
        source = 'o';
      }

      if (gclid) {
        source = 'g'
      } else if (fbclid) {
        source = 'f'
      } else if (msclkid) {
        source = 'm'
      }

      this.cookie.set('re', source, 3650, '/', (environment.production ? '.ggboost.com' : 'localhost'));

      if (!this.cookie.check('re-s')) {
        this.cookie.set('re-s', source, 3650, '/', (environment.production ? '.ggboost.com' : 'localhost'));
      }

      if (!this.cookie.check('re-t')) {
        this.cookie.set('re-t', Date.now().toString(), 3650, '/', (environment.production ? '.ggboost.com' : 'localhost'));
      }

      this.http.post('/check', {referrer:referrer}).subscribe({});

      const conversionSources = {
        'gclid': 'google',
        'fbclid': 'facebook',
        'msclkid': 'microsoft',
      }

      this.route.queryParams.subscribe(params => {
        if (params['gclid']) {
          const gclid = params['gclid'];
          this.cookie.set('gclid', gclid);
        }

        const paramKeys = Object.keys(params);
        const foundSources = paramKeys.filter(key => conversionSources.hasOwnProperty(key));
        const dataToSend = [];

        if (foundSources.length > 0) {
          const dataToSend = foundSources.map(sourceKey => ({
            sourceName: conversionSources[sourceKey],
            sourceParam: sourceKey,
            sourceId: params?.[sourceKey],
            url: this.router.url
          }));
          this.http.post('/seo-convertions', { data: dataToSend }).subscribe(resp => { });
        }
      });



      this.userService.getUser().pipe(take(1)).subscribe((user: any) => {
        if (!user) {
          this.userService.rememberLogin();
        }
      });

      this.router.events.subscribe(event => {
        // Scroll to top if accessing a page, not via browser history stack
        if (event instanceof NavigationEnd && !this.isPopState) {
          window.scrollTo(0, 0);
          this.isPopState = false;
        }

        // Ensures that isPopState is reset
        if (event instanceof NavigationEnd) {
          this.isPopState = false;
        }

        document.querySelector('body').classList.remove('mobile-nav');
      });
    }
  }

  ngAfterViewInit() {
    if (this.isBrowser) {

    }
  }
}