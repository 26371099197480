import {Directive, NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {DropdownMenuComponent} from './dropdown-menu.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dropdownTrigger]'
})
export class DropdownTriggerDirective {
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dropdownMenu]'
})
export class DropdownMenuDirective {
}

@NgModule({
  imports: [OverlayModule],
  declarations: [DropdownMenuComponent, DropdownTriggerDirective, DropdownMenuDirective],
  exports: [DropdownMenuComponent, DropdownTriggerDirective, DropdownMenuDirective]
})
export class DropdownMenuModule {
}
