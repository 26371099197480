import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from '../layout.component';
import {NavigationComponent} from '../components/navigation/navigation.component';
import {FooterComponent} from '../components/footer/footer.component';
import {NgxEzModalModule} from '../../../shared/modules/ngx-ez-modal/ngx-ez-modal.module';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '../../../shared/modules/button/button.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {DropdownMenuModule} from '../../../shared/modules/dropdown-menu/dropdown-menu.module';
import { TranslatePipeModule } from '../../../shared/pipes/translate.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgxEzModalModule,
        RouterModule,
        ButtonModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        SharedModule,
        DropdownMenuModule,
        TranslatePipeModule
    ],
  declarations: [
    LayoutComponent,
    NavigationComponent,
    FooterComponent
  ],
  exports: [
    LazyLoadImageModule,
    LayoutComponent,
    NavigationComponent,
    FooterComponent
  ]
})
export class BaseUiModule {
}
