import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {
  private validLangs = ['en', 'de', 'fr', 'es', 'pt'];
  private defaultLang = 'en';
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const lang = route.paramMap.get('lang');
    if (this.validLangs.includes(lang)) {
      return true;
    } else {
      const urlTree = this.router.parseUrl('/404');
      return urlTree;
    }
  }
}
