import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class ReviewService {
  private ratios: any;
  private reviews: any;
  private country: any;
  private lolReviews: any;
  private tftReviews: any;
  private valorantReviews: any;
  private wildRiftReviews: any;

  constructor(private http: HttpClient) {
  }

  getCountry(): Observable<any> {
    if (this.ratios) {
      return of(this.ratios);
    } else {
      return this.http.get('/auth/country').pipe(tap(val => this.country = val));
    }
  }

  getReviews() {
    if (this.reviews) {
      return of(this.reviews);
    } else {
      return this.http.get('/reviews/featured').pipe(tap(val => this.reviews = val));
    }
  }
}
